<template>
  <div>
    <div class="d-flex flex-row mb-3">
      <button
        :disabled="IsLoading"
        type="button"
        class="btn btn-white btn-sm text-nowrap"
        @click="closePage"
      >Go back</button>

      <h5 class="ml-2">Manage IPs - {{ feed.mandant_code }}/{{ feed.title }}</h5>
    </div>

    <h5 v-if="masks === null">Loading IPs..</h5>
    <table v-else class="table table-google table-masks mt-2">
      <thead>
        <tr>
          <th>IP</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(mask,index) in masks" :key="index">
          <td class="row-fit">
            <b-form-input
              class="d-block"
              style="width: 150px;"
              v-model="mask.value"
              size="sm"
              placeholder="IP address"
            ></b-form-input>
          </td>
          <td>
            <button
              :disabled="IsLoading"
              type="button"
              class="btn btn-danger btn-sm text-nowrap w-100"
              @click="removeEntry(index)"
            >Remove</button>
          </td>
        </tr>
        <tr>
          <td>
            <button
              :disabled="IsLoading"
              type="button"
              class="btn btn-white btn-sm text-nowrap w-100"
              @click="addEntry"
            >Add new entry</button>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <button
              :disabled="IsLoading || IsSaving || !CanSave"
              type="button"
              class="btn btn-primary btn-sm text-nowrap w-100"
              @click="saveMasks"
            >
              <span v-if="IsSaving">Saving changes..</span>
              <span v-else>Save changes</span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'FeedMasks',
  components: {},
  props: ['feed'],
  computed: {
    IsLoading() {
      return this.loadingCount > 0;
    },
    IsSaving() {
      return this.savingCount > 0;
    },
    CanSave() {
      return this.masks.every((mask) => mask.value.trim().length > 0);
    },
  },
  data() {
    return {
      loadingCount: 0,
      savingCount: 0,
      masks: null,
    };
  },
  methods: {
    closePage() {
      this.$emit('closePage');
    },
    fetchMasks() {
      this.loadingCount++;
      this.$http
        .get(`/feed/${this.feed.id}/masks`)
        .then((res) => {
          const masks = res.body.masks.map((mask) => ({ value: mask }));
          this.masks = masks;
        })
        .catch((err) => {
          alert(`Failed to fetch IPs: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    saveMasks() {
      if (this.IsSaving) return;
      this.savingCount++;
      this.$http
        .put(`/feed/${this.feed.id}/masks`)
        .send({ masks: this.masks.map((mask) => mask.value) })
        .then(() => {
          this.closePage();
        })
        .catch((err) => {
          alert(`Failed to save changes: ${err.response.text}`);
        })
        .finally(() => {
          this.savingCount--;
        });
    },
    addEntry() {
      this.masks.push({ value: '' });
    },
    removeEntry(maskIndex) {
      this.masks = this.masks.filter((mask, index) => index !== maskIndex);
    },
  },
  created() {
    this.fetchMasks();
  },
};
</script>

<style lang="scss" scoped>

.table-masks {
  max-width: 200px;
}

</style>
